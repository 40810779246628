import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";

import { fetchPubsData } from "./actions";
import Filter from "./Filter";
import Results from "./Results";
import styles from "./styles";

class Vacancies extends Component {
  state = {
    data: [],
    loading: false,
    error: undefined,
    filterOpen: false,
  };

  constructor(props) {
    super(props);

    this.updateData = this.updateData.bind(this);
    this.updateLoading = this.updateLoading.bind(this);
    this.updateError = this.updateError.bind(this);
  }

  componentDidMount() {
    this.showPubs();
  }

  filterBySuppress(pub) {
    return !pub.suppress;
  }

  filterByFacilities(pub, data) {
    const {
      requireFood,
      requireAccommodation,
      requireDogFriendly,
      requireBeerGarden,
    } = data;

    const hasFood = pub.tenancy_facility_food;
    const hasAccomodation = pub.tenancy_facility_accommodation;
    const isDogFriendly = pub.tenancy_facility_dog_friendly;
    const hasBeerGarden = pub.tenancy_facility_beer_garden;

    if (
      !requireFood &&
      !requireAccommodation &&
      !requireDogFriendly &&
      !requireBeerGarden
    ) {
      return true;
    }

    if (requireFood && !hasFood) return false;
    if (requireAccommodation && !hasAccomodation) return false;
    if (requireDogFriendly && !isDogFriendly) return false;
    if (requireBeerGarden && !hasBeerGarden) return false;

    return true;
  }

  filterByStatus(pub, filter) {
    const {
      requireNew,
      requireOffer,
      requireComing,
      requireRecentlyLet,
      requireToBeDiscussed,
      requireInvestmentPlanned,
    } = filter;

    const tenancyStatus = pub.tenancy_status;

    const isNew               = tenancyStatus?.includes("New");
    const isComing            = tenancyStatus?.includes("Coming soon");
    const isLet               = tenancyStatus?.includes("Recently let");
    const isDiscussed         = tenancyStatus?.includes("To be discussed");
    const hasOffer            = tenancyStatus?.includes("Under offer");
    const isInvestmentPlanned = tenancyStatus?.includes("Investment planned")

    if (
      (!!filter.name,
      !!filter.town,
      !requireComing &&
      !requireNew &&
      !requireRecentlyLet &&
      !requireToBeDiscussed &&
      !requireOffer &&
      !requireInvestmentPlanned)
    )
      return pub;

    if (requireComing && isComing) return pub;
    if (requireNew && isNew) return pub;
    if (requireRecentlyLet && isLet) return pub;
    if (requireToBeDiscussed && isDiscussed) return pub;
    if (requireOffer && hasOffer) return pub;
    if (requireInvestmentPlanned && isInvestmentPlanned) {
      return pub;
    }

    return false;
  }

  filterDuplicates(pub, i, pubs) {
    return pubs.findIndex((p) => p.id === pub.id) === i;
  }

  filterByCounty(pub, data) {
    if (!data.county) {
      return true;
    }

    return pub.county === data.county;
  }

  showPubs(data = {}) {
    fetchPubsData(data)
      .then(this.updateLoading(true))
      .then((responses) =>
        responses
          .map((res) => res.data.data)
          .flat()
          .filter((pub) => this.filterByFacilities(pub, data))
          .filter((pub) => this.filterByStatus(pub, data))
          .filter((pub, i, arr) => this.filterDuplicates(pub, i, arr))
          .filter((pub) => this.filterByCounty(pub, data))
      )
      .then((data) => this.updateData(data))
      .catch((err) => this.updateError(err))
      .finally(this.updateLoading(false));
  }

  toggleFilter() {
    this.setState({
      filterOpen: !this.state.filterOpen,
    });
  }

  updateData(data) {
    this.setState({
      data,
    });
  }

  updateLoading(loading) {
    this.setState({
      loading,
    });
  }

  updateError(error) {
    this.setState({
      error,
    });
  }

  render() {
    const { classes } = this.props;
    const { data, loading, error } = this.state;

    const combinedPubData = data
      .map((data) => {
        const { id } = data;
        const pubFound = this.props.wpData.find((d) => d.PubData.crmId === id);

        if (!pubFound) {
          return data;
        }

        const {
          displayLocation,
          featured,
          featuredImage,
          hasOwnWebsite,
          tenancyStatus,
          pubOoh,
          suppress,
          financials,
          levelOfInvestment,
        } = pubFound.PubData;
        const link = pubFound.link
          .replace("/pubs/", "/tenancy/")
          .replace(
            "https://corporate.robinsons-platform.brew-systems.co.uk/",
            "/"
          )
          .replace(
            "https://corporate.robinsons-platform.staging.brew-systems.co.uk/",
            "/"
          );

        return {
          ...data,
          displayLocation,
          featured,
          featuredImage,
          hasOwnWebsite,
          tenancyStatus,
          link,
          suppress,
          pubOoh,
          financials,
          levelOfInvestment,
        };
      })
      .filter((pub) => this.filterBySuppress(pub));

    return (
      <>
        <h1 className={classes.PageTitle}>Current Vacancies</h1>
        <Grid container spacing={8}>
          <Grid item xs={12} md={4} lg={3}>
            <button
              className={classes.ToggleFilter}
              onClick={() => this.toggleFilter()}
              data-open={this.state.filterOpen}
            >
              Filter
            </button>
            <div
              className={classes.FilterWrap}
              data-open={this.state.filterOpen}
            >
              <h3 className={classes.FilterTitle}>Filter</h3>
              <Filter
                classes={classes}
                showPubs={(data) => this.showPubs(data)}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Grid container spacing={2} style={{ marginBottom: 50 }}>
              <Results
                classes={classes}
                data={combinedPubData}
                loading={loading}
                error={error}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Vacancies);
