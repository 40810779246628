import whiteFilter from "../../images/white-filter.png";
import whiteClose from "../../images/white-close.png";
import filterIcon from "../../images/filter-icon.png";

import arrowBlack from "../../images/right-arrow-black.png";
import arrowWhite from "../../images/right-arrow.png";

export default (theme) => ({
  PageTitle: {
    fontFamily: theme.headerFont,
    margin: "32px 0",
    textTransform: "uppercase",
    letterSpacing: "0.056em",
    lineHeight: 1.25,
    fontSize: 24,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 56,
      marginTop: 80,
      paddingBottom: 40,
      marginBottom: 24,
      textAlign: "left",
      borderBottom: "1px solid " + theme.palette.common.black,
    },
  },
  ToggleFilter: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    backgroundImage: "url(" + whiteFilter + ")",
    backgroundPosition: "center right 15px",
    padding: "15px 20px",
    backgroundRepeat: "no-repeat",
    display: "block",
    width: "100%",
    textAlign: "left",
    fontFamily: theme.headerFont,
    letterSpacing: "0.06em",
    lineHeight: "19px",
    fontSize: 16,
    borderRadius: 4,
    border: "none",
    textTransform: "uppercase",
    transition: "backgroundColor .3s ease-in-out",
    outline: "none !important",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    '&[data-open="true"], &[data-open="true"]:hover, &[data-open="true"]:focus': {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: "url(" + whiteClose + ")",
      "&::before": {
        content: '"Close "',
        display: "inline-block",
        marginRight: 8,
      },
    },
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  FilterWrap: {
    height: 0,
    overflow: "hidden",
    opacity: 0,
    transition: "opacity .3s ease-in-out",
    backgroundColor: theme.palette.secondary.main,
    marginTop: -4,
    borderRadius: 4,
    marginBottom: 0,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      height: "auto !important",
      opacity: "1 !important",
      padding: 0,
      marginTop: 0,
      backgroundColor: "transparent",
      overflow: "visible !important",
      paddingBottom: 24,
      borderBottom: "3px solid " + theme.palette.primary.main,
      borderRadius: 0,
      marginBottom: 24,
    },
    '&[data-open="true"]': {
      opacity: 1,
      height: "auto",
      marginBottom: 40,
      padding: 20,
    },
  },
  FilterTitle: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      borderTop: "2px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontSize: 32,
      lineHeight: "40px",
      letterSpacing: "0.056em",
      textTransform: "uppercase",
      marginBottom: 50,
      paddingTop: 32,
      marginTop: -26,
      "&::after": {
        content: '""',
        width: 40,
        height: 40,
        display: "inline-block",
        backgroundPosition: "center",
        backgroundSize: 19,
        backgroundRepeat: "no-repeat",
        backgroundImage: "url(" + filterIcon + ")",
        verticalAlign: "bottom",
      },
    },
  },
  featuredCard: {
    position: "relative",
    boxShadow: "0 1px 24px rgba(0,0,0,0.2)",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      position: "relative",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    "&:after": {
      display: "table",
      clear: "both",
      content: '""',
    },
    "& .card-image": {
      position: "relative",
      borderBottom: "2px solid " + theme.palette.common.black,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 100%",
      },
      "& img": {
        display: "block",
        width: "100%",
        aspectRatio: "10/6",
        objectFit: "cover",
        objectPosition: "center",
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.up("md")]: {
          aspectRatio: "10/5.5",
        },
      },
      "& .tags": {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 2,
      },
    },
    "& .left-col": {
      [theme.breakpoints.up("md")]: {
        flex: "0 0 60%",
        borderRight: "2px solid " + theme.palette.common.black,
      },
      "& .ooh": {
        padding: 24,
        fontStyle: "italic",
        fontSize: 16,
        lineHeight: 1.6,
        letterSpacing: "0.056em",
        color: "#72ce9b",
        backgroundColor: theme.palette.common.black,
        margin: 0,
        [theme.breakpoints.up("md")]: {
          fontSize: 24,
        },
      },
      "& .wrap": {
        padding: 24,
        borderBottom: "3px solid " + theme.palette.common.black,
        [theme.breakpoints.up("md")]: {
          borderBottom: "none",
        },
        "& h3": {
          margin: 0,
          textTransform: "uppercase",
          letterSpacing: "0.04em",
          flex: "0 0 100%",
          minWidth: "100%",
          fontSize: 18,
          [theme.breakpoints.up("md")]: {
            fontSize: 22,
            marginBottom: 5,
          },
        },
        "& > p": {
          marginTop: 0,
          marginBottom: 0,
          fontStyle: "italic",
          letterSpacing: "0.04em",
          width: "100%",
          fontSize: 12,
          [theme.breakpoints.up("md")]: {
            fontSize: 16,
            marginBottom: 15,
          },
        },
        "& .facilities": {
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginTop: 5,
          height: 25,
          [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
            alignItems: "center",
            height: 32,
          },
          "& p": {
            margin: 0,
            fontStyle: "italic",
            letterSpacing: "0.04em",
            color: "#0e0e0e",
            fontSize: 11,
            [theme.breakpoints.up("md")]: {
              fontSize: 12,
            },
          },
          "& .icons": {
            fontSize: 0,
            lineHeight: 0,
            [theme.breakpoints.up("md")]: {
              marginLeft: 20,
            },
            "& img": {
              width: 24,
              marginLeft: 15,
              [theme.breakpoints.up("md")]: {
                width: 32,
                marginLeft: 20,
              },
            },
          },
        },
      },
    },
    "& .right-col": {
      [theme.breakpoints.up("md")]: {
        flex: "0 0 40%",
      },
      "& .financials": {
        padding: 24,
        backgroundColor: theme.palette.common.white,
        margin: 0,
        fontStyle: "italic",
        letterSpacing: "0.04em",
        color: "#0e0e0e",
        fontSize: 11,
        [theme.breakpoints.up("md")]: {
          fontSize: 12,
          padding: "40px 24px",
        },
        "& div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "2px solid #72ce9b",
          paddingTop: 10,
          marginTop: 10,
          "&:nth-of-type(1)": {
            borderTop: 0,
            marginTop: 0,
            paddingTop: 0,
          },
          "& span": {
            color: theme.palette.common.black,
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: 16,
            fontFamily: "Proxima Nova",
            [theme.breakpoints.up("md")]: {
              fontSize: 20,
            },
          },
        },
      },
      "& .card-link": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        width: "100%",
        letterSpacing: "0.04em",
        color: theme.palette.common.black,
        backgroundColor: "#72ce9b",
        borderTop: "3px solid " + theme.palette.common.black,
        fontFamily: theme.subHeaderFont,
        fontSize: 16,
        textTransform: "uppercase",
        textDecoration: "none",
        fontWeight: "bold",
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          bottom: 0,
          right: 0,
          left: "60%",
          width: "40%",
          borderTop: "none",
        },
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          "&:after": {
            backgroundImage: "url(" + arrowWhite + ")",
          },
        },
        "&:after": {
          width: 15,
          height: 15,
          backgroundImage: "url(" + arrowBlack + ")",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          content: '""',
          marginLeft: 10,
        },
      },
    },
  },
  Card: {
    position: "relative",
    boxShadow: "0 1px 24px rgba(0,0,0,0.2)",
    borderTop: "2px solid " + theme.palette.common.black,
    "&:after": {
      display: "table",
      clear: "both",
      content: '""',
    },
    "& .card-image": {
      position: "relative",
      "& img": {
        display: "block",
        width: "100%",
        aspectRatio: "10/6",
        objectFit: "cover",
        objectPosition: "center",
        position: "relative",
        zIndex: 1,
      },
      "& .tags": {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 2,
      },
    },
    "& .wrap": {
      padding: 24,
      [theme.breakpoints.up("md")]: {
        padding: 32,
      },
      "& h3": {
        margin: 0,
        textTransform: "uppercase",
        letterSpacing: "0.04em",
        flex: "0 0 100%",
        minWidth: "100%",
        fontSize: 18,
        [theme.breakpoints.up("md")]: {
          fontSize: 22,
          marginBottom: 5,
        },
      },
      "& > p": {
        marginTop: 0,
        marginBottom: 0,
        fontStyle: "italic",
        letterSpacing: "0.04em",
        width: "100%",
        fontSize: 12,
        [theme.breakpoints.up("md")]: {
          fontSize: 16,
          marginBottom: 15,
        },
      },
      "& .facilities": {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        marginTop: 5,
        height: 25,
        [theme.breakpoints.up("md")]: {
          display: "block",
          height: 60,
        },
        "& p": {
          margin: 0,
          fontStyle: "italic",
          letterSpacing: "0.04em",
          opacity: 0.5,
          fontSize: 11,
          [theme.breakpoints.up("md")]: {
            fontSize: 12,
          },
        },
        "& .icons": {
          fontSize: 0,
          lineHeight: 0,
          [theme.breakpoints.up("md")]: {
            marginTop: 10,
          },
          "& img": {
            width: 24,
            marginLeft: 15,
            [theme.breakpoints.up("md")]: {
              width: 32,
              marginLeft: 0,
              marginRight: 20,
            },
          },
        },
      },
      "& .financials": {
        margin: 0,
        fontStyle: "italic",
        letterSpacing: "0.04em",
        color: "#0e0e0e",
        [theme.breakpoints.up("md")]: {
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          borderTop: "1px solid #72ce9b",
          width: "calc(100% + 64px)",
          margin: "32px -32px -32px",
        },
        fontSize: 11,
        "& > div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "2px solid #72ce9b",
          paddingTop: 10,
          marginTop: 10,
          [theme.breakpoints.up("md")]: {
            flex: "0 0 33.333%",
            maxWidth: "33.333%",
            borderTop: "none",
            display: "block",
            textAlign: "center",
            padding: "20px 10px",
            marginTop: 0,
            "&:nth-of-type(2)": {
              borderLeft: "1px solid #72ce9b",
              borderRight: "1px solid #72ce9b",
            },
          },
          "& span": {
            color: theme.palette.common.black,
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: 16,
            fontFamily: "Proxima Nova",
            [theme.breakpoints.up("md")]: {
              display: "block",
              fontSize: 22,
            },
          },
          "& .icons": {
            "& img": {
              width: 24,
              marginLeft: 15,
            },
          },
        },
      },
    },
    "& .card-link": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 12,
      width: "100%",
      letterSpacing: "0.04em",
      color: theme.palette.common.black,
      backgroundColor: "#72ce9b",
      borderTop: "3px solid " + theme.palette.common.black,
      fontFamily: theme.subHeaderFont,
      fontSize: 16,
      textTransform: "uppercase",
      textDecoration: "none",
      fontWeight: "bold",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:after": {
          backgroundImage: "url(" + arrowWhite + ")",
        },
      },
      "&:after": {
        width: 15,
        height: 15,
        backgroundImage: "url(" + arrowBlack + ")",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        content: '""',
        marginLeft: 10,
      },
    },
  },
});
