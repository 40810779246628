import { nominalTypeHack } from 'prop-types';
import dropArrow from '../../images/drop-arrow.png';
import filterIcon from '../../images/filter-icon.png';

export default theme => ({
  Form: {
    
  },
  Label: {
    marginBottom: 10,
    color: theme.palette.secondary.contrastText,
    fontFamily: theme.headerFont,
    fontSize: 16,
    textTransform: 'uppercase',
    display: 'block',
    letterSpacing: '0.04em',
    lineHeight: 1.25,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.common.black,
      fontSize: 18,
    }
  },
  Input: {
    display: 'block',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    width: '100%',
    padding: 14,
    marginBottom: theme.spacing(2),
    border: 0,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    fontSize: 12,
    color: theme.palette.common.gray,
    backgroundColor: theme.palette.common.white,
    outline: 'none !important',
    fontFamily: theme.bodyFont,
    letterSpacing: '0.04em',
    fontStyle: 'italic',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgba(255,255,255,0.45)',
    }
  },
  Radius: {
    backgroundImage: 'url(' + dropArrow + ')',
    backgroundPosition: 'center right 15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 18,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgba(255,255,255,0.45)',
    }
  },
  FilterHeading: {
    marginBottom: 10,
    marginTop: 40,
    color: theme.palette.primary.main,
    fontFamily: theme.headerFont,
    fontSize: 16,
    textTransform: 'uppercase',
    display: 'block',
    letterSpacing: '0.04em',
    lineHeight: 1.25,
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingRight: 60,
      cursor: 'default !important'
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'url(' + dropArrow + ')',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 19,
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      transformOrigin: '50% 50%',
      transition: 'transform .3s ease-in-out',
    },
    '&[data-open="true"]': {
      '&::after': {
        transform: 'translateY(-50%) rotate(180deg)',
      }
    }
  },
  FacilitiesOptions: {
    
  },
  divider: {
    display: '100%',
    border: 'none',
    borderTop: '3px solid ' + theme.palette.primary.main,
  },
  OptionsCollapsed: {
    height: 0,
    overflow: 'hidden'
  },
  Option: {
      position: 'relative',
      display: 'block',
      width: '100%',
      paddingLeft: 36,
      fontSize: 14,
      lineHeight: '26px',
      letterSpacing: '0.032em',
      marginBottom: 16,
      marginTop: 16,
      color: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
          display: 'inline-block',
          marginRight: 32,
          fontSize: 14,
          color: theme.palette.common.black,
      },
      '& img': {
          display: 'inline-block',
          marginRight: 6,
          width: 25,
          verticalAlign: 'top',
          '&.light': {
            [theme.breakpoints.up('md')]: {
              display: 'none',
            }
          },
          '&.dark': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
              display: 'inline-block',
            }
          }
      },
      '& input': {
          position: 'absolute',
          opacity: 0,
          cursor: 'pointer',
          width: 0,
          height: 0,
          margin: 0,
          top: 0,
          right: 0,
      },
      '& .checkmark': {
          position: 'absolute',
          cursor: 'pointer',
          top: 0,
          left: 0,
          height: 24,
          width: 24,
          backgroundColor: theme.palette.common.white,
          border: '1px solid ' + theme.palette.primary.main,
          '&::after': {
              content: '""',
              position: 'absolute',
              display: 'none',
              left: 7,
              top: 3,
              width: 5,
              height: 10,
              border: 'solid ' + theme.palette.primary.contrastText,
              borderWidth: '0 3px 3px 0',
              transform: 'rotate(45deg)',
              boxSizing: 'content-box',
          
          },
      },
      '&:hover input ~ .checkmark': {
          border: '1px solid ' + theme.palette.primary.dark,
      },
      '& input:checked ~ .checkmark': {
          backgroundColor: theme.palette.primary.main,
      },
      '& input:checked ~ .checkmark::after': {
          display: 'block',
      }
  },
  Button: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 14,
    border: 'none',
    borderRadius: 4,
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    fontFamily: theme.headerFont,
    fontSize: 12,
    fontWeight: 'bold',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    '& img': {
      width: 14,
      marginBottom: 2,
      marginLeft: 20,
      verticalAlign: 'middle'
    }
  },
  CountyList: {
    backgroundImage: 'url(' + dropArrow + ')',
    backgroundPosition: 'center right 15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 18,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgba(255,255,255,0.45)',
    }
  }
})