import axios from 'axios';

export function fetchPubsData(data) {
  const url = `${process.env.GATSBY_CRM_ENDPOINT_URL}houses?fields=basic,distance,facilities,urls,tenancy&tenantable=1`;
  const headers = {
    'Accept':        'application/json',
    'Authorization': 'Bearer ' + process.env.GATSBY_CRM_ENDPOINT_AUTH_TOKEN,
    'Content-Type':  'application/json'
  }

  const { county, name, town, radius, showAll = false } = data;
  const requests = [];

  if (!name && !town) {
    return Promise.all([axios.get(`${url}&perPage=100`, { headers })])
  }

  if (name) {
    let term = name.replace(/ and /g, "*")
      .replace(/^and /g, "*")
      .replace(/ and$/g, "*")
      .replace(/&/g, "*")
      .replace(/ the /g, "*")
      .replace(/^the /g, "*")
      .replace(/ the$/g, "*")
      .replace(/ /g, "*");
      let oldTerm;
      do {
          oldTerm = term;
          term = term.replace(/\*\*/g, "*");
      } while (term !== oldTerm);
    requests.push(
      axios.get(`${url}&name=like:*${term}*`, { headers })
    )
  }

  if (town) {
    requests.push(
      axios.get(`${url}&location=geocode:${town},${radius}`, { headers })
    )
  }

  return Promise.all(requests);
}