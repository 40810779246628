import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import Link from "../Link";
import Labels from "./Labels";
import arrowImg from "../../images/right-arrow-black.png";

import iconAccom from "../../images/icon-bed-green.png";
import iconFood from "../../images/icon-food-green.png";
import iconDog from "../../images/icon-dog-friendly-green.png";
import iconGarden from "../../images/icon-beer-garden-green.png";

export default function Vacancy({
  classes,
  image,
  location,
  link,
  name,
  featured,
  status,
  description = "",
  facilityAccommodation,
  facilityBeerGarden,
  facilityDogFriendly,
  facilityFood,
  financials,
  levelOfInvestment,
}) {
  const showFacilities =
    facilityFood ||
    facilityAccommodation ||
    facilityDogFriendly ||
    facilityBeerGarden;

  const showFinancials =
    financials &&
    (financials.potentialFmtTurnover ||
      financials.annualRent ||
      financials.subTotal);

  if (featured) {
    return (
      <Grid item xs={12}>
        <div className={classes.featuredCard}>
          <div className="card-image">
            <img src={image} alt={name} />
            <div className="tags">
              <Labels status={status} featured={featured} />
            </div>
          </div>

          <div className="left-col">
            <div className="ooh">
              {description ? (
                description.replace("[displayLocation]", location)
              ) : (
                <br />
              )}
            </div>
            <div className="wrap">
              <h3>{name}</h3>
              <p>{location}</p>

              {showFacilities && (
                <div className="facilities">
                  <p>Facilities:</p>
                  <div className="icons">
                    {facilityFood && (
                      <img src={iconFood} alt="Food Avaliable" />
                    )}
                    {facilityAccommodation && (
                      <img src={iconAccom} alt="Accommodation Avaliable" />
                    )}
                    {facilityDogFriendly && (
                      <img src={iconDog} alt="Dog Friendly" />
                    )}
                    {facilityBeerGarden && (
                      <img src={iconGarden} alt="Beer Garden" />
                    )}
                  </div>
                </div>
              )}
              {!showFacilities && <div className="facilities" />}
            </div>
          </div>

          <div className="right-col">
            {showFinancials && (
              <div className="financials">
                <div>
                  FMT Turnover <span>£{financials.potentialFmtTurnover}</span>
                </div>

                <div>
                  Annual rent <span>£{financials.annualRent}</span>
                </div>

                <div>
                  Investment <span>£{levelOfInvestment.subTotal}</span>
                </div>
              </div>
            )}
            <Link to={link} className="card-link" alt={name}>
              View Opportunity
            </Link>
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={6}>
      <div className={classes.Card}>
        <div className="card-image">
          <img src={image} alt={name} />
          <div className="tags">
            <Labels status={status} featured={featured} />
          </div>
        </div>

        <div className="wrap">
          <h3>{name}</h3>
          <p>{location}</p>

          {showFacilities && (
            <div className="facilities">
              <p>Facilities:</p>
              <div className="icons">
                {facilityFood && <img src={iconFood} alt="Food Avaliable" />}
                {facilityAccommodation && (
                  <img src={iconAccom} alt="Accommodation Avaliable" />
                )}
                {facilityDogFriendly && (
                  <img src={iconDog} alt="Dog Friendly" />
                )}
                {facilityBeerGarden && (
                  <img src={iconGarden} alt="Beer Garden" />
                )}
              </div>
            </div>
          )}
          {!showFacilities && <div className="facilities" />}
          {showFinancials && (
            <div className="financials">
              <div>
                FMT Turnover <span>£{financials.potentialFmtTurnover}</span>
              </div>

              <div>
                Annual rent <span>£{financials.annualRent}</span>
              </div>

              <div>
                Investment <span>£{levelOfInvestment.subTotal}</span>
              </div>
            </div>
          )}
        </div>

        <Link to={link} className="card-link" alt={name}>
          View Opportunity
        </Link>
      </div>
    </Grid>
  );
}
