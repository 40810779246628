import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Vacancies from "../../components/Vacancies";

export default function VacanciesPage({ data }) {
  return (
    <Layout meta={{}} title="Vacancies">
      <section>
        <Vacancies wpData={data.allWpPub.nodes} />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query {
    allWpPub {
      nodes {
        PubData {
          crmId
          displayLocation
          facilityAccommodation
          facilityBeerGarden
          facilityDogFriendly
          facilityFood
          featured
          featuredImage
          localFile {
            id
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 920
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
          hasOwnWebsite
          name
          suppress
          tenancyStatus
          pubOoh
          financials {
            annualRent
            potentialFmtTurnover
          }
          levelOfInvestment {
            subTotal
          }
        }
        link
      }
    }
  }
`;
