import React, { useState } from "react";
import Vacancy from "./Vacancy";
import Grid from "@material-ui/core/Grid";

export default function Results({ classes, data, loading, error }) {
  if (error) {
    return <h2>Error when retrieving pubs data.</h2>;
  }

  if (loading) {
    return (
      <Grid item xs={12} style={{ marginBottom: 50 }}>
        Finding pubs...
      </Grid>
    );
  }

  if (!data.length) {
    return (
      <Grid item xs={12} style={{ marginBottom: 50 }}>
        There are no pubs matching the selected criteria. Please search again or
        contact us to discuss the type of pub you are looking for.
      </Grid>
    );
  }

  return data
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .sort((a, b) => (a.featured > b.featured ? -1 : 1))
    .map((vacancy, index) => {
      const location = vacancy.displayLocation;
      const status = vacancy.tenancyStatus;
      const {
        name,
        featured,
        featuredImage,
        link,
        pubOoh,
        tenancy_facility_accommodation,
        tenancy_facility_beer_garden,
        tenancy_facility_dog_friendly,
        tenancy_facility_food,
        financials,
        levelOfInvestment,
      } = vacancy;

      return (
        <Vacancy
          classes={classes}
          key={index}
          featured={featured}
          location={location}
          name={name}
          description={pubOoh}
          link={link}
          image={featuredImage}
          status={status}
          facilityAccommodation={tenancy_facility_accommodation}
          facilityBeerGarden={tenancy_facility_beer_garden}
          facilityDogFriendly={tenancy_facility_dog_friendly}
          facilityFood={tenancy_facility_food}
          financials={financials}
          levelOfInvestment={levelOfInvestment}
        />
      );
    });
}
